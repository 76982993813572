<template>
  <div>
    <h3>{{ $t('reportworker.title') }}</h3>
    <br />
    <v-divider></v-divider> <br />

    <v-card outlined rounded>
      <v-card-title>
        {{ $t('all.find') }}
        <v-menu
          v-model="mdte1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dte1"
              :label="$t('all.from')"
              prepend-icon="fa-calendar"
              readonly
              v-on="on"
              @blur="date1 = parseDate(dte1)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date1" @input="mdte1 = false" locale="ru-ru"></v-date-picker>
        </v-menu>

        <v-menu
          v-model="mdte2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dte2"
              :label="$t('all.to')"
              prepend-icon="fa-calendar"
              readonly
              v-on="on"
              @blur="date2 = parseDate(dte2)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date2" @input="mdte2 = false" locale="ru-ru"></v-date-picker>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="dataLoad()">
          {{ $t('all.showreport') }}
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data"
        :items-per-page="1000"
        hide-default-footer
        class="elevation-1"
        dense
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.name" @click="showJob(item)" style="cursor: pointer">
              <td>{{ item.worker_fio }}</td>
              <td>{{ item.status1 }}</td>
              <td>{{ item.status3 }}</td>
              <td>{{ item.status4 }}</td>
              <td>{{ item.cnt }}</td>
              <td>{{ item.smm }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="detail" transition="dialog-bottom-transition" scrollable>
      <v-card outlined rounded>
        <v-card-title class="headline">
          {{ $t('reportworker.workertitle', [wDetail.worker_fio]) }}
        </v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <thead>
              <tr>
                <th class="text-left">{{ $t('all.job') }}</th>
                <th class="text-left">{{ $t('all.date') }}</th>
                <th class="text-left">{{ $t('reportworker.statusjob') }}</th>
                <th class="text-left">{{ $t('all.item') }}</th>
                <th class="text-left">{{ $t('all.service') }}</th>
                <th class="text-left">{{ $t('reportworker.statusserv') }}</th>
                <th class="text-left">{{ $t('all.cost') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in dataDetail" :key="index" @click="jobEdit(item.id)" style="cursor: pointer">
                <td>{{ item.name }}</td>
                <td>{{ item.dte_create }}</td>
                <td>
                  <v-chip :color="item.job_status_cls" label x-small outlined>{{ item.status_name }}</v-chip>
                </td>
                <td>{{ item.item_name }}</td>
                <td>{{ item.service_name }}</td>
                <td>
                  <v-chip :color="item.service_status_cls" label x-small outlined>{{
                    item.service_status_name
                  }}</v-chip>
                </td>
                <td>{{ item.cost }}</td>
              </tr>
            </tbody> </v-simple-table
          ><br />
        </v-card-text>
        <v-card-actions>
          <v-btn @click.native="detail = false"> {{ $t('all.close') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <modal_jobedit v-if="modalJobEdit" v-bind:view="modalJobView" v-on:close="modalJobEdit = false"></modal_jobedit>
  </div>
</template>

<script>
import modal_jobedit from './../job/job_edit.vue'
export default {
  data() {
    return {
      detail: false,
      wDetail: [],
      dataDetail: [],
      mdte1: false,
      mdte2: false,
      date1: null,
      date2: null,
      data: [],
      dataJob: [],
      headers: [
        {
          text: 'ФИО',
          value: 'worker_fio',
        },
        { text: this.$t('status.new'), value: 'status1', align: 'right' },
        { text: this.$t('status.work'), value: 'status3', align: 'right' },
        { text: this.$t('status.completed'), value: 'status4', align: 'right' },
        { text: this.$t('all.count'), value: 'cnt', align: 'right' },
        { text: this.$t('all.cost'), value: 'smm', align: 'right' },
      ],
      modalJobEdit: false,
      modalJobView: [],
    }
  },
  computed: {
    dte1: function() {
      return this.formatDate(this.date1)
    },
    dte2: function() {
      return this.formatDate(this.date2)
    },
  },
  components: {
    modal_jobedit,
  },
  mounted: function() {
    var dt = new Date()
    var month = dt.getMonth() + 1
    var day = dt.getDate()
    var year = dt.getFullYear()
    month = month < 10 ? '0' + month : month
    day = day < 10 ? '0' + day : day
    //                    this.dte2 = day + '.' + month + '.' + year;
    //                    this.dte1 = '01.' + month + '.' + year;
    this.date1 = year + '-' + month + '-' + '01'
    this.date2 = year + '-' + month + '-' + day
  },
  methods: {
    dteUpd1(v) {
      this.dte1 = v
    },
    dteUpd2(v) {
      this.dte2 = v
    },
    formatDate(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate(date) {
      if (!date) {
        return null
      }
      const [dte] = date.split(' ')
      const [day, month, year] = dte.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    updateData: function(id, smm) {
      console.log('id', id)
      console.log('smm', smm)
      console.log('data', this.data)
      var r = this.data.find(w_ => w_.service === id)
      this.$set(r, 'salary_calc', smm)
    },
    dataLoad: function() {
      var t = this
      t.$store.commit('loadingStartShow', true)
      this.$http
        .post(this.$store.state.apiUrl + 'report/statworker', {
          dte1: t.dte1,
          dte2: t.dte2,
        })
        .then(
          response => {
            t.$store.commit('loadingStartShow', false)
            t.data = response.body
          },
          err => {
            console.log(err)
          },
        )
    },
    showJob: function(w) {
      var t = this
      t.wDetail = w
      t.$store.commit('loadingStartShow', true)
      this.$http
        .post(this.$store.state.apiUrl + 'report/statworkerjob', {
          id: w.id,
          dte1: t.dte1,
          dte2: t.dte2,
        })
        .then(
          response => {
            t.$store.commit('loadingStartShow', false)
            t.dataDetail = response.body
            t.detail = true
          },
          err => {
            console.log(err)
          },
        )
      return false
    },
    jobEdit: function(id) {
      this.modalJobEdit = true
      this.modalJobView = { id: id }
      return false
    },
  },
}
</script>

<style></style>
