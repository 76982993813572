<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="700">
    <v-card>
      <v-card-title class="headline" v-if="data.id">
        {{ $t('customer.edittitle') }}
      </v-card-title>
      <v-card-title class="headline" v-if="!data.id">
        {{ $t('customer.addtitle') }}
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col sm="12" md="6">
            <v-radio-group v-model="data.tpe" row>
              <v-radio :label="$t('customer.tpe1')" color="primary" value="1"></v-radio>
              <v-radio :label="$t('customer.tpe2')" color="primary" value="2"></v-radio>
            </v-radio-group>
            <v-text-field
              v-model="data.name"
              :label="nameTpe"
              required
              :rules="[v => !!v || $t('all.fieldnoempty')]"
            ></v-text-field>
            <v-select
              v-bind:items="groups"
              v-model="data.group"
              :label="$t('customer.group')"
              bottom
              item-text="name"
              item-value="id"
            ></v-select>
            <v-text-field v-model="data.phone" :label="$t('customer.phone')"></v-text-field>
            <v-text-field v-model="data.email" label="E-mail"></v-text-field>
            <v-menu
              lazy
              :close-on-content-click="false"
              v-model="menu2"
              transition="scale-transition"
              offset-y
              full-width
              :nudge-right="40"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :label="$t('customer.birthday')"
                  v-model="data.bday"
                  prepend-icon="fa-calendar"
                  readonly
                  hide-details
                  v-on="on"
                  @blur="date = parseDate(data.bday)"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                @input="
                  data.bday = formatDate($event)
                  menu2 = false
                "
                locale="ru-ru"
                no-title
                scrollable
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col sm="12" md="6">
            Доп. поля<br />
            <span v-for="fld in customerFields" v-bind:key="fld.id">
              <v-text-field v-if="fld.tpe == 1" :label="fld.name" v-model="fld.value"></v-text-field>
              <v-text-field v-if="fld.tpe == 2" :label="fld.name" v-model="fld.value"></v-text-field>
              <v-checkbox v-if="fld.tpe == 4" v-model="fld.value" :label="fld.name"></v-checkbox>
              <v-select
                v-if="fld.tpe == 5"
                v-model="fld.value"
                :items="fld.list.split(',')"
                :label="fld.name"
              ></v-select>
              <v-menu
                lazy
                v-if="fld.tpe == 3"
                :close-on-content-click="false"
                v-model="fld.value_"
                transition="scale-transition"
                offset-y
                full-width
                :nudge-right="40"
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :label="fld.name"
                    v-model="fld.value"
                    prepend-icon="fa-calendar"
                    readonly
                    hide-details
                    v-on="on"
                    @blur="date = parseDate(fld.value)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="
                    fld.value = formatDate($event)
                    fld.value_ = false
                  "
                  locale="ru-ru"
                  no-title
                  scrollable
                >
                </v-date-picker>
              </v-menu>
            </span>
          </v-col>
        </v-row>
        <v-text-field v-model="data.comment" :label="$t('customer.comment')"></v-text-field>
        <v-form v-model="valid" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 sm6> </v-flex>
            <v-flex xs12 sm6> </v-flex>
            <v-flex xs12 sm6> </v-flex>
            <v-flex xs12 sm6>
              <v-select
                v-bind:items="communication"
                v-model="data.communication"
                :label="$t('customer.community')"
                bottom
                item-text="name"
                item-value="id"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6> </v-flex>
            <v-flex xs12 sm6> </v-flex>
            <v-flex xs12 sm6>
              <v-text-field v-model="data.addr" :label="$t('customer.addr')"></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-select
                v-bind:items="recommendations"
                v-model="data.recommendation"
                :label="$t('customer.recommendation')"
                bottom
                item-text="name"
                item-value="id"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6>
              <v-checkbox v-model="data.flg_contract" :label="$t('customer.flgdogovor')"></v-checkbox>
            </v-flex>
            <v-flex xs12 sm6> </v-flex>
            <v-flex xs12> </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="$emit('close')"> {{ $t('all.close') }} </v-btn>
        <v-btn color="primary" dark @click.native="save">
          {{ $t('all.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['title', 'view'],
  name: 'modal_edit',
  data() {
    return {
      data: [],
      groups: [],
      recommendations: [],
      dialog: true,
      valid: false,
      btnDel: false,
      menu2: false,
      date: null,
      customerFields: [],
      // prmTpe: !!this.view.tpe ? this.view.tpe : 1,
      //groupSelected: !!this.view.group ? this.view.group : 1,
      //                recommendationSelected: !!this.view.recommendation ? this.view.recommendation : 1
    }
  },
  computed: {
    nameTpe: function() {
      return this.data.tpe == 1 ? this.$t('customer.name') : this.$t('customer.orgname')
    },
    communication: function() {
      return this.$store.state.spr.communication
    },
  },
  mounted: function() {
    var t = this
    this.btnDel = this.view ? true : false
    this.$store.dispatch('loadCommunication')
    console.log('this.view', this.view)
    if (this.view) {
      t.$store.commit('loadingStartShow', true)
      this.$http.post(this.$store.state.apiUrl + 'customer/index/' + this.view).then(
        response => {
          t.$store.commit('loadingStartShow', false)
          t.data = response.body
          t.customerFields = response.body.customerFields
          this.flgTpe(this.data.tpe)
        },
        err => {
          console.log(err)
        },
      )
    } else {
      t.$store.commit('loadingStartShow', true)
      this.$http.post(this.$store.state.apiUrl + 'customerfield/index/').then(
        response => {
          t.$store.commit('loadingStartShow', false)
          t.customerFields = response.body
        },
        err => {
          console.log(err)
        },
      )
    }

    this.$http.post(this.$store.state.apiUrl + 'customer/group/').then(
      response => {
        t.groups = response.body
        if (!t.data.group) {
          t.data.group = t.groups[0].id
        }
      },
      err => {
        console.log(err)
      },
    )
    this.$http.post(this.$store.state.apiUrl + 'recommendation/').then(
      response => {
        t.recommendations = response.body
      },
      err => {
        console.log(err)
      },
    )
  },
  methods: {
    del: function() {
      this.$emit('delete', this.data)
    },
    flgTpe: function(tpe) {
      this.data.tpe = tpe
    },
    save: function() {
      var t = this
      var arr = Object.assign({}, this.data)

      arr = JSON.parse(JSON.stringify(arr))
      // console.log('🚀 ~ file: customer_edit.vue ~ line 288 ~ arr', arr)

      // delete arr.stat

      delete arr['stat']
      console.log('arr', arr)
      arr['field'] = t['customerFields'].map(function(e) {
        if (e.value) {
          return JSON.stringify({ id: e.id, value: e.value })
        } else return null
      })
      delete arr['customerFields']

      // console.log('🚀 ~ file: customer_edit.vue ~ line 288 ~ arr2', arr)
      // if (t.valid) {
      t.$store.commit('loadingStartShow', true)
      this.$http
        .post(this.$store.state.apiUrl + 'customer/save/', {
          id: this.irule,
          customer: arr,
        })
        .then(
          response => {
            t.$store.commit('loadingStartShow', false)
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: t.$t(response.body.msg),
            })
            if (!response.body.err) {
              arr.id = response.body.id
              this.$emit('close', 'reload', arr)
            }
          },
          err => {
            console.log(err)
          },
        )
      // }
    },
    formatDate(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate(date) {
      if (!date) {
        return null
      }
      const [dte] = date.split(' ')
      const [day, month, year] = dte.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
}
</script>

<style></style>
