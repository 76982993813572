<template>
  <div>
    <v-dialog v-model="dialog" scrollable fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          <span v-if="!ijob.id">Добавление заказа</span>
          <span v-if="ijob.id">Редактирование заказа {{ ijob.name }}</span>
          <v-menu top v-if="ijob.id" v-model="isOpenJobStatus">
            <template v-slot:activator="{ on }">
              <v-chip :color="ijob.status_cls" label x-small outlined v-on="on">{{ ijob.status_name }}</v-chip>
            </template>

            <v-list dense subheader>
              <v-list-item v-for="(item, index) in sstatus" :key="index" @click="saveStatus('job', item.id, ijob.id)">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu top v-if="ijob.id">
            <template v-slot:activator="{ on }">
              <v-chip class="ma-2" color="primary" label x-small v-on="on">
                <v-icon left>fa-user </v-icon>{{ ijob.master_fio }}</v-chip
              >
            </template>

            <v-list dense subheader>
              <v-list-item v-for="(item, index) in users" :key="index" @click="saveMaster('job', item.id, ijob.id)">
                <v-list-item-title>{{ item.fio }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          {{ itemsCost }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-tabs fixed-tabs v-model="tab" slider-color="white">
            <v-tab href="#info">Информация</v-tab>
            <v-tab href="#item" :disabled="!!!ijob.id">Работы</v-tab>
            <v-tab href="#good" :disabled="!!!ijob.id">Товары</v-tab>
            <v-tab href="#pay" :disabled="!!!ijob.id">Оплата</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="info">
              <v-container fluid grid-list-xs>
                <v-layout wrap align-center>
                  <v-flex xs6>
                    <span v-if="customer.id > 0">
                      <h3>
                        Имя клиента:<a href="#" @click="customer_view_show = true">{{ customer.name }}</a>
                        <v-btn icon small @click="customer = []"><v-icon>fa-trash-o</v-icon></v-btn>
                      </h3>
                      <h3>Телефон: {{ customer.phone }}</h3>
                      <br />
                    </span>
                    <span v-else>
                      <v-autocomplete
                        v-model="customer"
                        :loading="isLoading"
                        :search-input.sync="search"
                        :items="customers"
                        item-text="allname"
                        item-value="id"
                        hide-details
                        label="Имя клиента"
                        placeholder="Начните вводить имя или телефон для поиска"
                        return-object
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              Не нашли клиента?
                              <strong><a href="#" @click="addCustomer()">Добавить</a></strong>
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title v-text="data.item.name"></v-list-item-title>
                            <v-list-item-subtitle v-text="data.item.phone"></v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete> </span
                    ><br />
                    <v-textarea outlined label="Комментарий" v-model="ijob.comment" @change="saveJob()"></v-textarea>
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      dense
                      hide-details
                      v-bind:items="tpes"
                      v-model="ijob.tpe"
                      label="Тип работы"
                      bottom
                      item-text="name"
                      item-value="id"
                      @change="saveJob()"
                    ></v-select>
                    <v-menu
                      lazy
                      :close-on-content-click="false"
                      v-model="menu2"
                      transition="scale-transition"
                      offset-y
                      full-width
                      :nudge-right="40"
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          label="Срок исполнения"
                          v-model="ijob.dte_finish"
                          prepend-icon="fa-calendar"
                          readonly
                          hide-details
                          v-on="on"
                          @blur="date = parseDate(ijob.dte_finish)"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        @input="
                          ijob.dte_finish = formatDate($event)
                          menu2 = false
                          saveJob()
                        "
                        locale="ru-ru"
                        no-title
                        scrollable
                      >
                      </v-date-picker>
                    </v-menu>
                    <v-text-field
                      label="Сылка для фотографий"
                      v-model="ijob.photo_link"
                      @change="saveJob()"
                    ></v-text-field>
                    <span v-for="fld in jobFields" v-bind:key="fld.id">
                      <v-text-field
                        v-if="fld.tpe == 1"
                        :label="fld.name"
                        v-model="fld.value"
                        @change="saveJobField(fld.id, fld.value)"
                      ></v-text-field>
                      <v-text-field
                        v-if="fld.tpe == 2"
                        :label="fld.name"
                        v-model="fld.value"
                        @change="saveJobField(fld.id, fld.value)"
                      ></v-text-field>
                      <v-checkbox
                        v-if="fld.tpe == 4"
                        v-model="fld.value"
                        :label="fld.name"
                        @change="saveJobField(fld.id, fld.value)"
                      ></v-checkbox>
                      <v-select
                        v-if="fld.tpe == 5"
                        v-model="fld.value"
                        :items="fld.list.split(',')"
                        :label="fld.name"
                        @change="saveJobField(fld.id, fld.value)"
                      ></v-select>
                      <v-menu
                        lazy
                        v-if="fld.tpe == 3"
                        :close-on-content-click="false"
                        v-model="fld.value_"
                        transition="scale-transition"
                        offset-y
                        full-width
                        :nudge-right="40"
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :label="fld.name"
                            v-model="fld.value"
                            prepend-icon="fa-calendar"
                            readonly
                            hide-details
                            v-on="on"
                            @blur="date = parseDate(fld.value)"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          @input="
                            fld.value = formatDate($event)
                            fld.value_ = false
                            saveJobField(fld.id, fld.value)
                          "
                          locale="ru-ru"
                          no-title
                          scrollable
                        >
                        </v-date-picker>
                      </v-menu>
                    </span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-tab-item>
            <v-tab-item value="item">
              <div v-for="(item, itemIndex) in items" v-bind:key="itemIndex">
                <v-row>
                  <v-col cols="4">
                    <v-combobox
                      v-model="item.name"
                      :items="itemname"
                      hide-no-data
                      label="Название объекта"
                      @change="saveItems()"
                      placeholder="Начните вводить название"
                      dense
                    >
                    </v-combobox>

                    <v-menu top>
                      <template v-slot:activator="{ on }">
                        <v-chip :color="item.status_cls" label x-small outlined v-on="on">{{
                          item.status_name
                        }}</v-chip>
                      </template>

                      <v-list dense subheader>
                        <v-list-item
                          v-for="(st, index) in sstatus"
                          :key="index"
                          @click="saveStatus('item', st.id, item.id)"
                        >
                          <v-list-item-title>{{ st.name }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-menu top v-if="ijob.id">
                      <template v-slot:activator="{ on }">
                        <v-chip class="ma-2" color="primary" label x-small v-on="on">
                          <v-icon left>fa-user </v-icon>{{ item.master_fio }}</v-chip
                        >
                      </template>

                      <v-list dense subheader>
                        <v-list-item
                          v-for="(uitem, index) in users"
                          :key="index"
                          @click="saveMaster('item', uitem.id, ijob.id, item.id)"
                        >
                          <v-list-item-title>{{ uitem.fio }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <span class="overline"
                      ><a v-if="!item.comment" href="#" @click="itemCommentShow(itemIndex)">(...)</a></span
                    >
                    <span v-if="item.comment"
                      ><br /><a href="#" @click="itemCommentShow(itemIndex)">{{ item.comment }}</a></span
                    >
                    <br /><v-btn color="error" @click="delItem(itemIndex)">Удалить</v-btn>
                    <h4>Стоимость:{{ itemCost(itemIndex) }}</h4>
                  </v-col>
                  <v-col cols="8">
                    <v-card tile>
                      <v-card-text>
                        <v-row>
                          <v-col>
                            <v-autocomplete
                              v-model="service"
                              :loading="isLoading"
                              :items="services"
                              item-text="name"
                              hide-details
                              label="Название работы"
                              placeholder="Начните вводить название"
                              return-object
                              @input="service = []"
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-title>
                                    Не нашли услугу?
                                    <strong>Добавить</strong>
                                  </v-list-item-title>
                                </v-list-item>
                              </template>
                              <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object' && true">
                                  <v-list-item-title v-text="data.item"></v-list-item-title>
                                </template>

                                <template v-else>
                                  <v-list-item-content @click="selService(itemIndex, data.item)">
                                    <v-list-item-title>
                                      {{ data.item.name }}
                                      <small
                                        >Стоимость:<b>{{ data.item.cost }}</b></small
                                      >
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete> </v-col
                          ><v-col sm="2">
                            <v-btn
                              color="info"
                              @click="
                                clickServiceModalItem = itemIndex
                                clickServiceModal = true
                              "
                              >Выбрать</v-btn
                            >
                          </v-col>
                        </v-row>
                        <v-simple-table dense>
                          <thead>
                            <tr>
                              <th class="text-left">Название</th>
                              <th class="text-left">Статус</th>
                              <th class="text-left" width="50px">Кол-во</th>
                              <th class="text-left">Мастер</th>
                              <th class="text-left" width="100px">Стоимость</th>
                              <th class="text-left" width="100px">Сумма</th>
                              <th class="text-left" width="20px"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(sr, index) in item.service" :key="sr.name">
                              <td>
                                {{ sr.name }}
                                <span class="overline"
                                  ><a v-if="!sr.comment" href="#" @click="serviceCommentShow(itemIndex, index)"
                                    >(...)</a
                                  ></span
                                >
                                <span v-if="sr.comment"
                                  ><br /><a href="#" @click="serviceCommentShow(itemIndex, index)">{{
                                    sr.comment
                                  }}</a></span
                                >
                              </td>

                              <td>
                                <v-menu top>
                                  <template v-slot:activator="{ on }">
                                    <v-chip :color="sr.status_cls" label x-small outlined v-on="on">{{
                                      sr.status_name
                                    }}</v-chip>
                                  </template>

                                  <v-list dense subheader>
                                    <v-list-item
                                      v-for="(st, index) in sstatus"
                                      :key="index"
                                      @click="saveStatus('service', st.id, sr.id)"
                                    >
                                      <v-list-item-title>{{ st.name }}</v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </td>
                              <td>
                                <v-text-field
                                  dense
                                  @change="saveItems()"
                                  single-line
                                  hide-details
                                  width="20px"
                                  height="20"
                                  v-model="sr.quantity"
                                ></v-text-field>
                              </td>
                              <td>
                                <v-menu top v-if="ijob.id">
                                  <template v-slot:activator="{ on }">
                                    <v-chip class="ma-2" color="primary" label x-small v-on="on">
                                      <v-icon left>fa-user </v-icon>{{ sr.master_fio }}</v-chip
                                    >
                                  </template>

                                  <v-list dense subheader>
                                    <v-list-item
                                      v-for="(uitem, index) in users"
                                      :key="index"
                                      @click="
                                        sr.master = uitem.id
                                        saveItems(true)
                                      "
                                    >
                                      <v-list-item-title>{{ uitem.fio }}</v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </td>
                              <td>
                                <v-text-field
                                  dense
                                  @change="saveItems()"
                                  hide-details
                                  width="10px"
                                  height="20"
                                  v-model="sr.cost"
                                ></v-text-field>
                              </td>
                              <td>{{ sr.quantity * sr.cost }}</td>
                              <td>
                                <v-btn icon small @click="delRow(itemIndex, index)"><v-icon>fa-trash-o</v-icon></v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <hr />
              </div>

              <v-btn color="success" @click="newItem()">Добавить объект</v-btn>
            </v-tab-item>
            <v-tab-item value="good">
              <v-row>
                <v-col>
                  <v-autocomplete
                    v-model="good"
                    :loading="isLoading"
                    :items="goodsFilter"
                    item-text="name"
                    hide-details
                    label="Название товара"
                    placeholder="Начните вводить название"
                    return-object
                    @input="good = []"
                    :search-input.sync="searchGood"
                  >
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object' && true">
                        <v-list-item-title v-text="data.item"></v-list-item-title>
                      </template>

                      <template v-else>
                        <v-list-item-content @click="selGood(data.item)">
                          <v-list-item-title>
                            {{ data.item.name }}
                            <small
                              >Стоимость:<b>{{ data.item.cost }}</b> Остаток:<b>{{ data.item.quantity }}</b> Склад:<b>{{
                                data.item.storage_name
                              }}</b></small
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete> </v-col
                ><v-col sm="2">
                  <v-btn
                    color="info"
                    @click="
                      clickGoodModalItem = itemIndex
                      clickGoodModal = true
                    "
                    >Выбрать</v-btn
                  >
                </v-col>
              </v-row>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th class="text-left">Название</th>
                    <th class="text-left" width="100px">Кол-во</th>
                    <th class="text-left" width="200px">Стоимость</th>
                    <th class="text-left" width="100px">Сумма</th>
                    <th class="text-left" width="20px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(g, index) in goodItems" :key="g.id">
                    <td>{{ g.name }}</td>
                    <td>
                      <v-text-field
                        @change="saveGoodItems()"
                        single-line
                        hide-details
                        width="20px"
                        height="20"
                        v-model="g.quantity"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                        @change="saveGoodItems()"
                        hide-details
                        width="20px"
                        height="20"
                        v-model="g.cost"
                      ></v-text-field>
                    </td>
                    <td>{{ g.quantity * g.cost }}</td>
                    <td>
                      <v-btn icon small @click="delGoodRow(index)"><v-icon>fa-trash-o</v-icon></v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-tab-item>
            <v-tab-item value="pay">
              <div class="row">
                <div class="col">
                  Итого: {{ itemsCost }}<br />
                  Оплачено:{{ ijob.pay }}<br />
                  Долг:<b>{{ itemsCost - ijob.pay }}</b
                  ><br />
                  <v-text-field label="Скидка" v-model="ijob.sale" @change="saveJob()"></v-text-field>
                </div>
                <div class="col">
                  <p v-for="row in ipay" v-bind:key="row.id">
                    {{ row.dte }}<br />{{ row.account_name }}:
                    <b>{{ row.cost }}</b>
                    <v-btn v-if="smena.id > 0 && smena.id == row.smena" icon small @click="delPay(row.id)"
                      ><v-icon>fa-trash-o</v-icon></v-btn
                    >
                  </p>
                </div>
                <div class="col" v-if="smena.id > 0">
                  <v-select
                    dense
                    hide-details
                    v-bind:items="accounts"
                    v-model="account"
                    label="Способ оплаты"
                    bottom
                    item-text="name"
                    item-value="id"
                  ></v-select>
                  <v-text-field label="Сумма" v-model="pay"></v-text-field>
                  <v-btn v-on:click="sendPay()" color="primary">Внести оплату</v-btn>
                  <v-btn v-on:click="retPay()" color="error">Возврат</v-btn>
                </div>
                <div class="col" v-else>
                  Операции возможны только в открытой смене!
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" outlined v-on:click="showLog()">
            <v-icon>fa-history</v-icon>
          </v-btn>
          <v-menu offset-y v-if="ijob.id">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark v-on="on"> Печать </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="item in docprints" :key="item.id" v-on:click="docPrint(item.id)">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>
          <v-btn outlined @click="$emit('close', 'reload')">Закрыть</v-btn>&nbsp;

          <v-btn v-if="!ijob.id" @click="newJob()" color="primary">Продолжить</v-btn>
          <v-btn v-if="ijob.id" @click="delJob = true" color="error">Удалить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="serviceComment">
      <v-card>
        <v-card-title class="headline">Комментарий к работе</v-card-title>
        <v-card-text>
          <v-textarea v-model="serviceCommentText" outlined label="Комментарий"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text v-on:click="serviceComment = false"> Закрыть </v-btn>
          <v-btn color="primary" v-on:click="serviceCommentSave()">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="itemComment">
      <v-card>
        <v-card-title class="headline">Комментарий к объекту</v-card-title>
        <v-card-text>
          <v-textarea v-model="itemCommentText" outlined label="Комментарий"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="itemComment = false"> Закрыть </v-btn>
          <v-btn color="primary" @click="itemCommentSave()"> Сохранить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="delJob" scrollable>
      <v-card>
        <v-card-title class="headline">Удаление заказа</v-card-title>
        <v-card-text> Удалить заказ {{ ijob.name }}? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="delJob = false"> Закрыть </v-btn>
          <v-btn color="error" @click="delJobSend()"> Удалить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="clickServiceModal" scrollable>
      <v-card>
        <v-card-title class="headline">Выбор работы</v-card-title>
        <v-card-text>
          <span v-if="clickServiceModalGrp == ''">
            <v-btn
              color="primary"
              v-for="(grp, idx) in groups"
              v-bind:key="idx"
              @click="clickServiceModalGrp = grp.id"
              outlined
              block
              >{{ grp.name }}</v-btn
            >
          </span>
          <span v-if="clickServiceModalGrp != ''">
            <v-btn
              color="primary"
              v-for="(item, idx) in groupServices"
              v-bind:key="idx"
              @click="selService(clickServiceModalItem, item)"
              :outlined="!item.flg_sel"
              block
              >{{ item.name }} <v-spacer></v-spacer>{{ item.cost }}</v-btn
            >
          </span>
        </v-card-text>
        <v-card-actions>
          Выбрано услуг:{{ itemCost() }}
          <v-spacer></v-spacer>
          <v-btn text v-if="clickServiceModalGrp != ''" @click="clickServiceModalGrp = ''">
            Назад
          </v-btn>
          <v-btn
            text
            @click="
              clickServiceModalGrp = ''
              clickServiceModal = false
            "
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <customer_view
      v-if="customer_view_show"
      v-bind:view="customer.id"
      v-on:close="customer_view_show = false"
    ></customer_view>
    <customer_edit v-if="customer_edit_show" v-bind:view="customer.id" v-on:close="setCustomer"></customer_edit>
    <modal_log v-if="modal_log" v-bind:id="ijob.id" v-on:close="modal_log = false"></modal_log>
  </div>
</template>

<script>
import customer_edit from './../customer/customer_edit.vue'
import modal_log from './job_log.vue'
export default {
  props: ['title', 'view', 'inCustomer'],
  name: 'job_edit',
  // beforeCreate: function () {
  //   this.$options.components.customer_view = require('./../customer/customer_view.vue')
  // },
  components: {
    customer_edit,
    modal_log,
    customer_view: () => import('./../customer/customer_view.vue'),
  },
  data() {
    return {
      dialog: true, //modal dialog
      jobFields: [], //modal dialog
      customer_view_show: false, // flag customer show
      customer_edit_show: false, // flag customer_edit show
      customer: [], //customer job
      isLoading: false,
      search: null,
      customers: [], //customer list search
      menu2: false,
      date: null,
      itemname: [],
      searchitemname: '',
      isOpenJobStatus: false,
      good: [],
      goods: [],
      searchGood: null,
      goodItems: [],
      service: [],
      serviceData: [],
      items: [],
      igood: [],
      ijob: { comment: '', tpe: 1, customer: [] },
      ipay: [],
      pay: '',
      actItem: [],
      account: '',
      modal_log: '',
      tab_model: 'info',
      serviceComment: false,
      serviceCommentText: '',
      serviceCommentid: [],
      itemComment: false,
      itemCommentText: '',
      itemCommentId: [],
      tab: null,
      delJob: false,
      clickServiceModal: false,
      clickServiceModalGrp: '',
      clickServiceModalItem: '',
      item_name: '123',
    }
  },
  watch: {
    customer(val) {
      this.ijob.customer = val.id
    },
    search(val) {
      var t = this
      this.isLoading = true
      t.$http
        .post(t.$store.state.apiUrl + 'customer/SearchCustomer/', {
          text: val,
        })
        .then(
          response => {
            t.customers = response.body
            console.log('customers', t.customers)
            t.isLoading = false
          },
          err => {
            console.log(err)
          },
        )
    },
    searchGood(val) {
      var t = this
      this.isLoading = true
      t.loadGood(val)
    },
  },
  computed: {
    smena: function() {
      return this.$store.state.auth.smena
    },
    docprints: function() {
      return this.$store.state.spr.docprint
    },
    users: function() {
      return this.$store.state.spr.user
    },
    tpes: function() {
      return this.$store.state.spr.job_tpe
    },
    sstatus: function() {
      return this.$store.state.spr.status
    },
    accounts: function() {
      return this.$store.state.spr.account
    },
    itemsCost: function() {
      let cost = 0,
        t = this
      //                console.log('t.items',t.items);
      t.items.forEach(function(e) {
        //                    console.log('e.service',e.service);
        if (e.service)
          e.service.forEach(function(entry) {
            cost += parseFloat(entry.cost) * parseFloat(entry.quantity)
          })
      })
      t.goodItems.forEach(function(e) {
        //                    console.log('e',e);
        cost += parseFloat(e.cost) * parseFloat(e.quantity)
      })
      cost = cost - parseFloat((cost * t.ijob.sale) / 100)
      return cost
    },
    services: function() {
      var t = this,
        g = '',
        ret = []
      t.$store.state.spr.service.forEach(function(e) {
        if (g == '') {
          ret.push({
            header: e.group_name,
          })
          g = e.group
        } else if (g != '' && g != e.group) {
          ret.push({
            divider: true,
          })
          ret.push({
            header: e.group_name,
          })
          g = e.group
        }
        if (e.id != '') {
          e.service = e.id
          e.id = ''
        }
        //console.log('e', e);
        ret.push(e)

        //                console.log(e);
      })
      console.log('services', ret)
      return ret
    },
    goodsFilter: function() {
      var t = this,
        g = '',
        ret = []
      t.goods.forEach(function(e) {
        if (g == '') {
          ret.push({
            header: e.group_name,
          })
          g = e.group
        } else if (g != '' && g != e.group) {
          ret.push({
            divider: true,
          })
          ret.push({
            header: e.group_name,
          })
          g = e.group
        }
        if (e.id != '') {
          e.service = e.id
          e.id = ''
        }
        //console.log('e', e);
        ret.push(e)

        //                console.log(e);
      })
      console.log('goods', ret)
      return ret
    },
    groups: function() {
      var t = this,
        g = '',
        ret = []
      t.$store.state.spr.service.forEach(function(e) {
        if (g == '') {
          //                        console.log('item!!',item);
          ret.push({
            name: e.group_name,
            id: e.group,
          })
          g = e.group
        } else if (g != '' && g != e.group) {
          //                        console.log('item!!',item);
          ret.push({
            name: e.group_name,
            id: e.group,
          })
          g = e.group
        }
      })
      //                console.log('groups', ret);
      return ret
    },
    groupServices: function() {
      var t = this,
        ret = [],
        item = t.clickServiceModalItem
      ret = t.$store.state.spr.service.filter(e => e.group == t.clickServiceModalGrp)
      ret.forEach(function(entry) {
        //                    console.log('item',item);
        entry.flg_sel = false
        if (!!t.items[item].service && t.items[item].service.length > 0) {
          //                        console.log('t.items[item].service',t.items[item].service);
          t.items[item].service.forEach(function(f) {
            console.log('f.service', f.service)
            console.log('entry.service', entry.service)
            if (f.service == entry.service) {
              entry.flg_sel = true
            }
          })
        }
      })
      console.log('groupServices', ret)
      return ret
    },
  },
  mounted: function() {
    var t = this,
      job = this.view != undefined && !!this.view.id ? this.view.id : ''
    t.dialog = true
    //            alert(t.inCustomer);
    if (t.inCustomer) {
      t.customer = t.inCustomer
    }
    this.$store.dispatch('loadUser')
    this.$store.dispatch('loadSmena')
    this.$store.dispatch('loadAccount')
    this.$store.dispatch('loadDocprint')
    this.$store.dispatch('loadJobTpe').then(() => {
      //                console.log('!!!t.ijob.tpe', !!!t.ijob.tpe)
      //                console.log('t.ijob.tpe', t.ijob.tpe)
      if (!t.ijob.tpe || t.ijob.tpe == 1) {
        t.$set(t.ijob, 'tpe', '1')
      } else {
        t.$set(t.ijob, 'tpe', {
          id: t.view.tpe,
          //                    name: 'Платный'
        })
      }
      //                console.log('t.ijob', t.ijob)
      t.$set(t.view, 'cost', 0)
      t.$set(t.view, 'costtpe', {
        id: '1',
      })
      this.$store.dispatch('loadService')
      this.loadGood('')
      // t.view.jobtpe=;
      //                    console.log('t.view.jobtpe',t.view.jobtpe)
      // }
    })
    t.loadItemName()
    if (job > 0) {
      this.$http.post(this.$store.state.apiUrl + 'job/load/' + job).then(
        response => {
          //                    t.datas = response.body;
          t.items = response.body.item
          t.ijob = response.body.job
          t.ipay = response.body.pay
          t.goodItems = response.body.good
          t.jobFields = response.body.jobFields
          t.customer = {
            id: t.ijob.customer,
            name: t.ijob.customer_name,
            phone: t.ijob.customer_phone,
          }
          console.log('items', t.items)
        },
        err => {
          console.log(err)
        },
      )
    } else {
      this.ijob.tpe = 1
    }
  },
  methods: {
    loadItemName() {
      var t = this
      t.$http.post(t.$store.state.apiUrl + 'job/SearchItemName/').then(
        response => {
          t.itemname = response.body
          console.log('itemname', t.itemname)
        },
        err => {
          console.log(err)
        },
      )
    },
    loadGood(val) {
      var t = this
      t.$http
        .post(t.$store.state.apiUrl + 'good/SearchGood/', {
          text: val,
        })
        .then(
          response => {
            t.goods = response.body
            console.log('goods', t.goods)
            t.isLoading = false
          },
          err => {
            console.log(err)
          },
        )
    },
    addCustomer() {
      this.customer_edit_show = true
      return false
    },
    setCustomer(flg, prm) {
      if (flg == 'reload') {
        this.customer = prm
      }
      this.customer_edit_show = false
      console.log('customer', prm)
    },
    loadData() {
      //Загружаем данные при обновлении
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'job/load/' + t.ijob.id).then(
        response => {
          //                    t.datas = response.body;
          t.items = response.body.item
          t.ijob = response.body.job
          t.ipay = response.body.pay
          t.goodItems = response.body.good
          console.log('goodItems', response.body.good)
          t.customer = {
            id: t.ijob.customer,
            name: t.ijob.customer_name,
            phone: t.ijob.customer_phone,
          }
        },
        err => {
          console.log(err)
        },
      )
    },
    delPay(id) {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'job/payDel/', {
          job: t.ijob.id,
          id: id,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              t.loadData()
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    sendPay() {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'job/pay/', {
          job: t.ijob.id,
          account: t.account,
          cost: t.pay,
          smena: t.smena.id,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              t.loadData()
              t.isOpenJobStatus = true
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    retPay() {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'job/payRet/', {
          job: t.ijob.id,
          account: t.account,
          cost: t.pay,
          smena: t.smena.id,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              t.loadData()
              t.isOpenJobStatus = true
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    saveMaster(tpe, master, job, item) {
      var t = this
      if (tpe == 'job') {
        this.$http
          .post(this.$store.state.apiUrl + 'job/jobMasterSave/', {
            job: t.ijob.id,
            master: master,
          })
          .then(
            response => {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: response.body.msg,
              })
              if (!response.body.err) {
                t.loadData()
              }
            },
            err => {
              console.log(err)
            },
          )
      }
      if (tpe == 'item') {
        this.$http
          .post(this.$store.state.apiUrl + 'job/jobMasterSave/', {
            item: item,
            master: master,
          })
          .then(
            response => {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: response.body.msg,
              })
              if (!response.body.err) {
                t.loadData()
              }
            },
            err => {
              console.log(err)
            },
          )
      }
    },
    saveStatus(tpe, status, id) {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'job/statusSave/', {
          tpe: tpe,
          status: status,
          id: id,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              t.loadData()
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    serviceCommentShow(itemIndex, index) {
      //                console.log('itemIndex, index',itemIndex, index)
      this.serviceCommentText = this.items[itemIndex]['service'][index].comment
      this.serviceComment = true
      this.serviceCommentid = { itemIndex: itemIndex, index: index }
      //                console.log('this.items1 ',this.items )
    },
    itemCommentShow(index) {
      //                console.log('itemIndex, index',itemIndex, index)
      this.itemCommentText = this.items[index].comment
      this.itemComment = true
      this.itemCommentId = { index: index }
      //                console.log('this.items1 ',this.items )
    },
    itemCommentSave() {
      this.itemComment = false
      this.items[this.itemCommentId.index].comment = this.itemCommentText
      this.itemCommentText = ''
      this.items = this.items.filter(function(item) {
        return item
      })
      this.saveItems(false)
    },
    serviceCommentSave() {
      this.serviceComment = false
      this.items[this.serviceCommentid.itemIndex]['service'][
        this.serviceCommentid.index
      ].comment = this.serviceCommentText
      this.serviceCommentText = ''
      this.items = this.items.filter(function(item) {
        return item
      })
      this.saveItems(false)
    },
    delRow(i, e) {
      // удаление работы
      console.log('e', e)
      this.items[i]['service'].splice(e, 1)
      this.items = this.items.filter(function(item) {
        return item
      })
      this.saveItems(false)
    },
    delGoodRow(e) {
      // удаление работы
      this.goodItems.splice(e, 1)
      this.goodItems = this.goodItems.filter(function(item) {
        return item
      })
      this.saveGoodItems(false)
    },
    selService(item, e) {
      //выбор работы из списка
      var t = this,
        i = 0,
        n = 0
      console.log('items', this.items)
      console.log('item', item)
      if (!!this.items[item].service && this.items[item].service.length > 0) {
        this.items[item].service.forEach(function(f) {
          if (f.service == e.service) {
            t.delRow(item, n)
            i++
          }
          n++
        })
      }
      if (i == 0) {
        t.$set(e, 'quantity', '1')
        t.$set(e, 'comment', '')
        t.$set(e, 'master', t.items[item].master)
        t.$set(e, 'status', t.sstatus[0].id)
        t.$set(e, 'status_name', t.sstatus[0].name)
        t.$set(e, 'status_cls', t.sstatus[0].cls)
        if (t.items[item].master > 0) t.$set(e, 'master_fio', t.users.filter(e => e.id == t.items[item].master)[0].fio)
        var f = JSON.parse(JSON.stringify(e))
        t.$set(t.items[item].service, t.items[item].service.length, f)
      }
      t.saveItems(true)
    },
    selGood(e) {
      //выбор работы из списка
      var t = this,
        i = 0
      console.log('items', this.goods)
      console.log('e', e)
      /*
                 if (!!this.goodItems[item].good && this.goodItems[item].good.length > 0) {
                 this.goodItems[item].good.forEach(function (f) {
                 if (f.good == e.good) {
                 f.quantity++;
                 i++;
                 }
                 });
                 }*/
      if (i == 0) {
        t.$set(e, 'quantity', '1')
        var f = JSON.parse(JSON.stringify(e))
        t.$set(t.goodItems, t.goodItems.length, f)
      }
      t.saveGoodItems(true)
    },
    delJobConf: function() {
      var t = this
      this.$bvModal
        .msgBoxConfirm('Удалить заказ?', {
          title: 'Подтверждение',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Удалить',
          cancelTitle: 'Отменить',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            t.delJob()
          }
        })
        .catch(err => {
          console.log(err)
          // An error occurred
        })
    },
    delJobSend: function() {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'job/delete/', {
          job: t.ijob.id,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              t.$emit('close', 'reload')
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    newJob: function() {
      var flg_check = true,
        t = this
      var arr = Object.assign({}, this.ijob)

      if (!this.ijob.customer) {
        this.errCustomer = 'Клиент должен быть выбран'
        flg_check = false
      } else {
        this.errCustomer = false
      }
      console.log(flg_check)
      if (flg_check) {
        this.$http
          .post(this.$store.state.apiUrl + 'job/new/', {
            job: arr,
            smena: t.smena.id,
          })
          .then(
            response => {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: response.body.msg,
              })
              if (!response.body.err) {
                t.ijob = response.body.job
                t.jobFields = response.body.jobFields
              }
            },
            err => {
              console.log(err)
            },
          )
      }
    },
    saveJob: function() {
      var flg_check = true,
        t = this
      var arr = Object.assign({}, this.ijob)

      if (!this.ijob.customer) {
        this.errCustomer = 'Клиент должен быть выбран'
        flg_check = false
      } else {
        this.errCustomer = false
      }
      console.log(flg_check)
      if (flg_check) {
        this.$http
          .post(this.$store.state.apiUrl + 'job/save/', {
            job: arr,
          })
          .then(
            response => {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: response.body.msg,
              })
              if (!response.body.err) {
                //                                    t.ijob = response.body.job;
              }
            },
            err => {
              console.log(err)
            },
          )
      }
    },
    newItem: function() {
      this.items.push({
        service: [],
        name: '',
        status: this.ijob.status,
        status_cls: this.ijob.status_cls,
        status_name: this.ijob.status_name,
      })
      this.saveItems(true)
    },
    delItem: function(index) {
      console.log('index', index)
      this.items.splice(index, 1)
      this.items = this.items.filter(function(item) {
        return item
      })
      this.saveItems(false)
    },
    customer_viewClose: function() {
      this.customer_view_show = false
    },
    formatDate(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate(date) {
      if (!date) {
        return null
      }
      const [dte] = date.split(' ')
      const [day, month, year] = dte.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    saveItemStatus: function(item, status) {
      item.status = status.id
      this.saveItems(true)
    },
    _saveStatus: function(e) {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'job/statusSave/', {
          job: t.ijob.id,
          status: e,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              t.ijob = response.body.job
              t.loadData()
              //                                t.saveItems(true)
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    jobInfo: function(data) {
      console.log('data', data)
      this.ijob = data
    },
    printzn: function() {
      this.$http
        .post(this.$store.state.apiUrl + 'job/printzn/', {
          job: this.ijob.id,
        })
        .then(
          response => {
            if (response.body.err > 0) {
              console.log(response.body.err)
            } else {
              var new_win = window.open()
              var content =
                '<html><head>' + '<title>Заказ наряд</title></head><body>' + response.body.html + '</body></html>'
              new_win.document.write(content)
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    bco: function() {
      this.$http
        .post(this.$store.state.apiUrl + 'job/printbco/', {
          job: this.ijob.id,
        })
        .then(
          response => {
            if (response.body.err > 0) {
              console.log(response.body.err)
            } else {
              var new_win = window.open()
              var content = '<html><head>' + '<title>БСО</title></head><body>' + response.body.html + '</body></html>'
              new_win.document.write(content)
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    docPrint: function(id) {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'job/docprint/', {
          job: this.ijob.id,
          id: id,
        })
        .then(
          response => {
            if (response.body.err > 0) {
              console.log(response.body.err)
            } else {
              var new_win = window.open()
              var content =
                '<html><head>' + '<title></title></head><body>' + t.escapeHtml(response.body.html) + '</body></html>'
              new_win.document.write(content)
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    escapeHtml: function(str) {
      if (typeof str == 'string') {
        str = str.replace(/&gt;/gi, '>')
        str = str.replace(/&lt;/gi, '<')
        str = str.replace(/&#039;/g, "'")
        str = str.replace(/&quot;/gi, '"')
        str = str.replace(/&amp;/gi, '&') /* must do &amp; last */
      }
      return str
    },
    del: function() {
      //            console.log(123);
      this.$emit('delete', this.view)
    },
    saveItems: function(reload = false) {
      //Сохранение всех работ
      var t = this
      var j = JSON.stringify(this.items)
      this.$http
        .post(this.$store.state.apiUrl + 'job/saveItems/', {
          job: t.ijob.id,
          items: j,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              if (reload) {
                console.log('reload', reload)
                t.loadData()
              }
              //
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    saveGoodItems: function(reload = false) {
      //Сохранение всех работ
      var t = this
      var j = JSON.stringify(this.goodItems)
      console.log('goodItems', this.goodItems)
      console.log('goodItems2', j)
      this.$http
        .post(this.$store.state.apiUrl + 'good/sellSave/', {
          job: t.ijob.id,
          smena: t.smena.id,
          good: j,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              if (reload) {
                console.log('reload', reload)
                t.loadData()
              }
              //
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    saveJobField: function(field, value) {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'job/SaveJobField/', {
          job: t.ijob.id,
          field: field,
          value: value,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              // this.$emit('close', 'reload')
            }
            // /                   t.ResultLogin=response.body;
            //                    this.$store.commit('mLogin', t.ResultLogin)
            //                    console.log(this.$route.query.redirect);
            //                    console.log(this.redirectToAfterLogin);
            //                    this.$router.push(this.redirectToAfterLogin)
          },
          err => {
            console.log(err)
          },
        )
    },
    save: function() {
      var i = 0,
        flg_check = true,
        prmItems = [],
        t = this
      var arr = Object.assign({}, this.ijob),
        pay
      //            pay['cost']=this.icost;
      //            pay['tpe']=this.icost_tpe;
      pay = { cost: this.icost, tpe: this.icost_tpe }
      for (var res in this.items) {
        prmItems[i] = this.items[res]
        i++
      }

      console.log(prmItems)
      if (!this.ijob.customer) {
        this.errCustomer = 'Клиент должен быть выбран'
        flg_check = false
      } else {
        this.errCustomer = false
      }
      console.log(flg_check)
      if (flg_check) {
        this.$http
          .post(this.$store.state.apiUrl + 'job/save/', {
            job: arr,
            items: prmItems,
            pay: pay,
          })
          .then(
            response => {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: response.body.msg,
              })
              if (!response.body.err) {
                this.$emit('close', 'reload')
              }
              // /                   t.ResultLogin=response.body;
              //                    this.$store.commit('mLogin', t.ResultLogin)
              //                    console.log(this.$route.query.redirect);
              //                    console.log(this.redirectToAfterLogin);
              //                    this.$router.push(this.redirectToAfterLogin)
            },
            err => {
              console.log(err)
            },
          )
      }
      //            console.log(this.resources);
      //            console.log(this.view);
      //            this.modal='edit';
      //            this.modalTitle = 'Добавление роли';
      //            this.modalView='';
      //                        this.showModal = true;
      //                        this.modalContent = this.tpl_edit;
      //                      $('#'+this.tag).modal('show')
    },
    showLog: function() {
      this.modal_log = true
      //            this.modalView=view;
    },

    itemCost: function(id) {
      let cost = 0,
        t = this
      console.log('itemCost', id)
      if (id == undefined) {
        id = t.clickServiceModalItem
      }
      if (id === undefined || id === '') {
        console.log('return 0 ', id)
        return 0
      }
      console.log('itemCost', id)
      t.items[id].service.forEach(function(entry) {
        cost += parseFloat(entry.cost) * parseFloat(entry.quantity)
      })
      console.log('cost', cost)
      return cost
    },
  },
}
</script>

<style>
.styled-input label[for] {
  height: 10px;
  font-size: 10pt;
}
.v-tab {
  border: solid 1px #fff !important;
  margin-right: 0.5em;
  border-radius: 4px;
  margin-bottom: -4px;
}
.v-tab--active {
  border: solid 1px #ddd !important;
  border-bottom: solid 1px #fff !important;
}
.v-tabs-bar__content {
  border-bottom: 1px solid #dee2e6;
}
.v-tabs-slider-wrapper {
  bottom: -1px;
}
</style>
