<template>
  <v-dialog v-model="dialog" scrollable persistent>
    <v-progress-circular
      :size="70"
      :width="7"
      color="purple"
      indeterminate
      v-if="flg_load"
    ></v-progress-circular>

    <v-card v-else>
      <v-card-title class="headline"
        >История изменений заказа &nbsp;&nbsp;&nbsp;<b>
          {{ view.name }}</b
        ></v-card-title
      >
      <v-card-text>
        Статус:{{ view.status_name }}<br />
        Мастер:{{ view.master_fio }}<br />
        Cтоимость:{{ view.cost }}<br />
        <v-simple-table dense>
          <thead>
            <tr>
              <th class="text-left">Время</th>
              <th class="text-left">Пользователь</th>
              <th class="text-left">Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in datas" :key="item.id">
              <td>{{ item.dte }}</td>
              <td>{{ item.user_fio }}</td>
              <td>{{ escapeHtml(item.comment) }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click.native="$emit('close')">Закрыть</v-btn>
        <v-btn color="success" dark @click="addComment">Добавить</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="historyComment">
      <v-card>
        <v-card-title class="headline"
          >Добавить комментарий к истории</v-card-title
        >
        <v-card-text>
          <v-textarea
            v-model="historyCommentText"
            outlined
            label="Комментарий"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="historyComment = false"> Закрыть </v-btn>
          <v-btn color="primary" @click="historyCommentSave()">
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      datas: [],
      view: [],
      flg_load: true,
      modal_comment: false,
      dialog: true,
      historyComment: false,
      historyCommentText: '',
    }
  },
  components: {},
  computed: {},

  mounted: function () {
    this.jobLoad()
  },
  methods: {
    //загрузка данных о заказе
    jobLoad: function () {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'job/job/' + this.id).then(
        (response) => {
          t.view = response.body.job
          t.dataLoad()
        },
        (err) => {
          console.log(err)
        }
      )
    },
    //загрузка лога
    dataLoad: function () {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'job/log/' + this.id).then(
        (response) => {
          t.flg_load = false
          t.datas = response.body
        },
        (err) => {
          console.log(err)
        }
      )
    },
    modalClose: function (prm) {
      this.modal_comment = ''
      if (prm == 'reload') {
        this.dataLoad()
      }
    },
    showComment: function (data) {
      console.log(data)
      this.$set(data, 'show', !data.show)
      //            data.show=!data.show
    },
    escapeHtml: function (str) {
      if (typeof str == 'string') {
        str = str.replace(/&gt;/gi, '>')
        str = str.replace(/&lt;/gi, '<')
        str = str.replace(/&#039;/g, "'")
        str = str.replace(/&quot;/gi, '"')
        str = str.replace(/&amp;/gi, '&') /* must do &amp; last */
      }
      return str
    },
    addComment: function () {
      this.historyCommentText = ''
      this.historyComment = true
    },
    historyCommentSave: function () {
      var t = this

      if (this.historyCommentText != '') {
        this.$http
          .post(this.$store.state.apiUrl + 'job/historyCommentSave/', {
            txt: this.historyCommentText,
            job: t.id,
          })
          .then(
            (response) => {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: response.body.msg,
              })
              if (!response.body.err) {
                t.historyCommentText = ''
                t.historyComment = false
                t.dataLoad()
              }
            },
            (err) => {
              console.log(err)
            }
          )
      }
    },
  },
}
</script>

<style></style>
